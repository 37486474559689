import { gql, useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import copyText from '../../core/util/copyText';
import SpinnerIcon from '../../main/component/icon/SpinnerIcon';
import useAlterBookingState from '../hooks/useAlterBookingState';

interface Props {
    bookingId: number;
    onClose(): void;
}

const checkinFormMessageQuery = gql`
    query CheckinFormMessageQuery($id: Int!) {
        guestDetailTemplate: messageTemplate(
            name: "Ask for guest detail form"
        ) {
            value
        }
        booking(id: $id) {
            id
            reference
            guestCheckinUrl
            guests {
                firstName
            }
        }
    }
`;

const BookingMessageGeneratorDialog = ({
    bookingId,
    onFocusLost,
}: {
    bookingId: number;
    onFocusLost: () => void;
}) => {
    const [copied, setCopied] = useState(false);
    const {
        loading,
        data: {
            booking: { reference, guestCheckinUrl, guests },
            guestDetailTemplate: { value: guestDetailMessageTemplate },
        } = {
            booking: {
                reference: '',
                guestCheckinUrl: '',
                guestInstructionsUrl: '',
                guests: [{ firstName: '' }],
            },
            guestDetailTemplate: { value: '' },
            guestInstructionsTemplate: { value: '' },
        },
    } = useQuery<{
        booking: {
            reference: string;
            guestCheckinUrl: string;
            guestInstructionsUrl: string;
            guests: { firstName: string }[];
        };
        guestDetailTemplate: { value: string };
    }>(checkinFormMessageQuery, { variables: { id: bookingId } });

    const [alterBookingState, { loading: alterBookingStateLoading }] =
        useAlterBookingState(bookingId);

    if (loading || alterBookingStateLoading) {
        return <SpinnerIcon />;
    }

    const message = guestDetailMessageTemplate
        .replace('${firstName}', guests[0].firstName)
        .replace('${guestCheckinUrl}', guestCheckinUrl);

    return (
        <Dialog open={true}>
            <DialogContent>
                <TextField multiline rows={2} fullWidth value={message} />
            </DialogContent>
            <DialogActions>
                <Box
                    minWidth={600}
                    display="flex"
                    flexDirection="column"
                    gap={2}
                >
                    <Button
                        variant={copied ? undefined : 'contained'}
                        onClick={() => {
                            copyText(message)
                                .then(() => {
                                    setCopied(true);
                                })
                                .catch(error => {
                                    throw error;
                                });
                        }}
                    >
                        {copied ? 'Copied' : 'Copy message'}
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            window.open(
                                `https://www.airbnb.co.uk/hosting/reservations/details/${reference}`
                            );
                        }}
                    >
                        Open booking
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            alterBookingState({
                                variables: {
                                    id: bookingId,
                                    fields: { detailsRequested: true },
                                },
                            })
                                .catch(error => {
                                    throw error;
                                })
                                .finally(() => {
                                    onFocusLost();
                                });
                        }}
                    >
                        Mark as sent
                    </Button>
                    <Button variant="contained" onClick={onFocusLost}>
                        Close
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

const BookingMessageGenerator = ({ bookingId, onClose }: Props) => {
    return (
        <BookingMessageGeneratorDialog
            bookingId={bookingId}
            onFocusLost={onClose}
        />
    );
};

export default BookingMessageGenerator;
