import { gql, useMutation } from '@apollo/client';
import Box from '@mui/material/Box';
import { useState } from 'react';
import DropZone from '../../base-ui/components/DropZone';
import ErrorBox from '../../core/components/ErrorBox';
import { baseUrl } from '../client';
import DropBox from './common/DropBox';
import SpinnerIcon from './icon/SpinnerIcon';

const invoiceListUploadMutation = gql`
    mutation uploadInvoiceList($file: Upload!) {
        uploadInvoiceList(file: $file)
    }
`;

// cspell:words Pénzforgalom

const InvoiceList = () => {
    const [uploadInvoiceList] = useMutation(invoiceListUploadMutation);
    const [isFileDraggedIn, setIsFileDraggedIn] = useState(false);
    const [state, setState] = useState<undefined | 'loading' | Error>(
        undefined
    );

    const dragEnter = () => {
        setIsFileDraggedIn(true);
    };
    const dragLeave = () => {
        setIsFileDraggedIn(false);
    };

    const upload = (invoiceFileExport: File) => {
        setState('loading');
        (async () => {
            const formData = new FormData();
            formData.append('file', invoiceFileExport);

            const token = localStorage.getItem('token');

            await fetch(`${baseUrl}/api/upload-invoice-list`, {
                method: 'POST',
                headers: {
                    authorization: token ? `bearer ${token}` : '',
                },
                body: formData,
            });

            /*
            await uploadInvoiceList({
                variables: {
                    file: invoiceFileExport,
                },
            });
            */
            setState(undefined);
        })().catch(error => {
            setState(error);
        });
    };

    if (state === 'loading') {
        return (
            <Box display="flex" flexDirection="column" alignItems="center">
                <SpinnerIcon />
            </Box>
        );
    }

    if (state) {
        return <ErrorBox error={state} />;
    }

    return (
        <Box p={1}>
            <DropZone
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDropFiles={files => {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    upload(files.item(0)!);
                }}
            >
                <DropBox highlight={isFileDraggedIn}>
                    Drop "Pénzforgalom (Excel)" document.
                </DropBox>
            </DropZone>
        </Box>
    );
};

export default InvoiceList;
