/* eslint @typescript-eslint/ban-types: 0 */
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

// const baseUrl = 'http://localhost:5001';
export const baseUrl =
    import.meta.env.VITE__API_URL || 'https://hosting.antal.xyz';

const httpLink = createHttpLink({
    uri: `${baseUrl}/graphql`,
});

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('token');
    return {
        headers: {
            ...headers,
            authorization: token ? `bearer ${token}` : '',
        },
    };
});

export const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions: {
        query: {
            fetchPolicy: 'no-cache',
        },
    },
});

export const apiRawFetch = async (path: string, method = 'get', body?: {}) =>
    fetch(`${baseUrl}/${path}`, {
        method,
        headers: {
            'Content-Type': 'application/json',
            Authorization: `bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(body),
    });
