import { gql, useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import assert from '../../core/util/assert';
import SpinnerIcon from '../../main/component/icon/SpinnerIcon';
import useAlterBookingState from '../hooks/useAlterBookingState';

const createExpression = () => /is (\d*)(\D*)./gm;

const lockboxCodes = [
    {
        name: 'Summer',
        code: '517066293',
        from: new Date('2024-04-29T07:00:00Z'),
        to: new Date('2024-09-01T07:00:00Z'),
    },
    {
        name: 'Autumn',
        code: '295398715',
        from: new Date('2024-08-27T07:00:00Z'),
        to: new Date('2024-12-01T07:00:00Z'),
    },
    {
        name: 'Winter',
        code: '987446666',
        from: new Date('2024-12-01T07:00:00Z'),
        to: new Date('2025-03-01T07:00:00Z'),
    },
    {
        name: 'Spring',
        code: '750973603',
        from: new Date('2025-03-06T10:00:00Z'),
        to: new Date('2025-06-01T10:00:00Z'),
    },
];

const LockboxCodeDialog = ({
    bookingId,
    onFocusLost,
}: {
    bookingId: number;
    onFocusLost: () => void;
}) => {
    const { data, loading: dataLoading } = useQuery<{
        booking: {
            guestName: string;
            checkin: string;
            checkout: string;
        };
    }>(
        gql`
            query lockboxCodeDialogQuery($id: Int!) {
                booking(id: $id) {
                    guestName
                    checkin
                    checkout
                }
            }
        `,
        {
            variables: {
                id: bookingId,
            },
        }
    );
    const [alterBookingState, { loading: alterBookingStateLoading }] =
        useAlterBookingState(bookingId);
    const [value, setValue] = useState('');

    useEffect(() => {
        if (value && createExpression().test(value)) {
            setValue(value.matchAll(createExpression()).next().value![1]);
        } else if (data?.booking) {
            const checkinTime = new Date(`${data.booking.checkin}T05:00:00Z`);
            const checkoutTime = new Date(`${data.booking.checkout}T23:00:00Z`);

            const lockboxCode = lockboxCodes.find(
                item => item.from <= checkinTime && item.to >= checkoutTime
            );

            if (lockboxCode) {
                setValue(lockboxCode.code);
            }
        }
    }, [value]);

    if (dataLoading || alterBookingStateLoading) {
        return <SpinnerIcon />;
    }

    assert(data);

    const { booking } = data;

    return (
        <Dialog open>
            <DialogContent>
                <div>Name: {booking.guestName}</div>
                <div>Checkin: {booking.checkin}</div>
                <div>Checkout: {booking.checkout}</div>
                <TextField
                    value={value}
                    onChange={event => {
                        setValue(event.target.value);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Box display="flex" gap={2}>
                    <Button variant="contained" onClick={onFocusLost}>
                        Close
                    </Button>
                    <Button
                        variant="contained"
                        disabled={!value}
                        onClick={() => {
                            alterBookingState({
                                variables: {
                                    id: bookingId,
                                    fields: {
                                        lockboxCode: value,
                                    },
                                },
                            })
                                .catch(saveError => {
                                    throw saveError;
                                })
                                .finally(() => {
                                    onFocusLost();
                                });
                        }}
                    >
                        Set lockbox code
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
};

const LockboxCodeForm = ({
    bookingId,
    onClose,
}: {
    bookingId: number;
    onClose(): void;
}) => {
    return <LockboxCodeDialog bookingId={bookingId} onFocusLost={onClose} />;
};

export default LockboxCodeForm;
