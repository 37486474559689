/* eslint max-lines: ["error", 400] */
import { gql, useApolloClient } from '@apollo/client';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import TextCopyInput from '../../../base-ui/components/TextCopyInput';
import useQuery from '../../../core/hooks/useQuery';
import { toDateTime } from '../../../domain/date';
import createInvoiceCode from '../../../invoice/automation/createInvoice.js?raw';
import SpinnerIcon from '../../../main/component/icon/SpinnerIcon';
import { calculateInvoiceItems } from '../../calculation/invoiceBreakdown';
import useAlterBookingState from '../../hooks/useAlterBookingState';

const invoiceViewQuery = gql`
    query InvoiceViewDialogQuery($id: Int!) {
        booking(id: $id) {
            id
            reference
            guestName
            revenue
            cleaningFee
            checkin
            checkout
            guests {
                id
                firstName
                lastName
                countryNameEN
                birthDate
                postcode
                address
                price
                touristTax
            }
        }
    }
`;

type InvoiceViewResult = {
    booking: {
        id: number;
        reference: string;
        guestName: string;
        revenue: number;
        cleaningFee: number;
        checkin: string;
        checkout: string;
        guests: {
            id: number;
            firstName: string;
            lastName: string;
            birthDate: string;
            countryNameEN: string;
            postcode: string;
            address: string;
            price: number;
            touristTax: number;
        }[];
    };
};

/* eslint no-mixed-operators: 0 */
export const InvoiceViewDialog = ({
    bookingId,
    onFocusLost,
}: {
    bookingId: number;
    onFocusLost: () => void;
}) => {
    const client = useApolloClient();
    const [saving, setSaving] = useState(false);
    const [invoiceId, setInvoiceId] = useState('');
    const [alterBookingState] = useAlterBookingState(bookingId);
    const { loading, data, error } = useQuery<InvoiceViewResult>(
        invoiceViewQuery,
        { variables: { id: bookingId } }
    );

    if (error) {
        return <Dialog open>{error.toString()}</Dialog>;
    }

    if (loading) {
        return <SpinnerIcon />;
    }

    if (saving) {
        return <SpinnerIcon />;
    }

    const { booking } = data;
    const { revenue, checkin, checkout, guests } = booking;
    const [{ firstName, lastName, countryNameEN, postcode, address }] = guests;

    const invoiceItems = calculateInvoiceItems(booking);

    /* spellchecker: disable */
    return (
        <Dialog open onClose={onFocusLost}>
            <DialogContent>
                <Box display="flex" flexDirection="column" gap={2}>
                    <Box display="flex" gap={5}>
                        <Box width={150}>Customer</Box>
                        <TextField value={`${firstName} ${lastName}`} />
                    </Box>
                    <Box display="flex" gap={5}>
                        <Box width={150}>Revenue</Box>
                        <TextField value={revenue} />
                    </Box>
                    <Box display="flex" flexDirection="column" gap={2}>
                        <TextCopyInput
                            type="textarea"
                            value={`${createInvoiceCode}run(${JSON.stringify({
                                reference: booking.reference,
                                guestName: `${firstName} ${lastName}`,
                                paymentDate: new Date()
                                    .toISOString()
                                    .substring(0, 10),
                                invoiceItems,
                                countryName: countryNameEN,
                                postcode,
                                address,
                                accommodationItemDescription: `${
                                    guests.length
                                } vendégnek ${
                                    checkout
                                        ? toDateTime(checkout).diff(
                                              toDateTime(checkin),
                                              'days'
                                          ).days
                                        : 0
                                } éjszakára, takarítással`,
                            })});`}
                        />
                        <Button
                            variant="contained"
                            onClick={() => {
                                window.open(
                                    'https://www.szamlazz.hu/szamla/?page=szamlaszerkeszto',
                                    '_blank'
                                );
                            }}
                        >
                            Open site
                        </Button>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box display="flex" gap={2}>
                    <TextField
                        value={invoiceId}
                        onChange={event => {
                            setInvoiceId(event.target.value);
                        }}
                    />
                    <Button
                        variant="contained"
                        disabled={!invoiceId || !booking.id}
                        onClick={() => {
                            setSaving(true);
                            alterBookingState({
                                variables: {
                                    id: booking.id,
                                    fields: {
                                        invoiceId,
                                    },
                                },
                            })
                                .then(async () => client.resetStore())
                                .catch(saveError => {
                                    throw saveError;
                                })
                                .finally(() => {
                                    setSaving(false);
                                    onFocusLost();
                                });
                        }}
                    >
                        Set invoice
                    </Button>
                </Box>
            </DialogActions>
        </Dialog>
    );
    /* spellchecker: enable */
};

const InvoiceView = ({ bookingId }: { bookingId: number }) => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <Button
                variant="contained"
                onClick={() => {
                    setOpen(true);
                }}
            >
                Invoice view
            </Button>
            {open && (
                <InvoiceViewDialog
                    bookingId={bookingId}
                    onFocusLost={() => {
                        setOpen(false);
                    }}
                />
            )}
        </>
    );
};

export default InvoiceView;
