import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { createRef, RefObject, useState } from 'react';
import CopyIcon from './icon/CopyIcon';

const ValueInput = ({
    value,
    inputRef,
    type,
}: {
    value: string;
    type?: 'textarea' | 'hidden';
    inputRef: RefObject<HTMLInputElement> | RefObject<HTMLTextAreaElement>;
}) => {
    if (type === 'textarea') {
        return (
            <textarea
                style={{ flexGrow: 1 }}
                value={value}
                readOnly
                ref={inputRef as RefObject<HTMLTextAreaElement>}
            />
        );
    }

    if (type === 'hidden') {
        return (
            <input
                value={value}
                style={{ width: 1, background: 'transparent' }}
                type="text"
                readOnly
                ref={inputRef as RefObject<HTMLInputElement>}
            />
        );
    }

    return (
        <input
            value={value}
            type="text"
            readOnly
            ref={inputRef as RefObject<HTMLInputElement>}
        />
    );
};

const TextCopyInput = ({
    value,
    type,
}: {
    value: string;
    type?: 'textarea' | 'hidden';
}) => {
    const [copied, setCopied] = useState(false);
    const inputRef = createRef<HTMLInputElement | HTMLTextAreaElement>() as
        | RefObject<HTMLInputElement>
        | RefObject<HTMLTextAreaElement>;

    return (
        <Box display="flex" flexDirection="column" gap={2}>
            <ValueInput value={value} type={type} inputRef={inputRef} />
            <Button
                variant="contained"
                onClick={() => {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    inputRef.current!.select();
                    document.execCommand('copy');
                    setCopied(true);
                }}
            >
                {copied ? 'Copied' : <CopyIcon />}
            </Button>
        </Box>
    );
};

export default TextCopyInput;
