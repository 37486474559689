import classnames from 'clsx';
import UploadIcon from '../icon/UploadIcon';

import type { ReactNode } from 'react';
import './DropBox.scss';

const DropBox = ({
    children,
    highlight,
}: {
    children: ReactNode;
    highlight?: boolean;
}) => (
    <div className={classnames('DropBox', { highlight })}>
        {children} <UploadIcon />
    </div>
);

export default DropBox;
