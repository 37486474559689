const extractCookieFromCurl = (curlCommand: string) => {
    const result = /-H 'cookie: ([^\n']+)'/g.exec(curlCommand);

    if (!result) {
        const result = /-b '([^\n']+)'/g.exec(curlCommand);

        if (result) {
            return result[1];
        }

        return undefined;
    }

    return result[1];
};

export default extractCookieFromCurl;
