import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import type ApiKey from '../../../../../packages/entities/ApiKey';
import useUpdateApiKey from '../../core/hooks/useUpdateApiKey';

const ApiKeyEdit = ({ apiKey }: { apiKey: ApiKey }) => {
    const updateApiKey = useUpdateApiKey();
    const [value, setValue] = useState(apiKey.value);

    return (
        <Box display="flex" flexDirection="column" gap={4}>
            {apiKey.name}
            <TextField
                value={value}
                onChange={event => {
                    setValue(event.target.value);
                }}
                multiline
                sx={{ width: 500 }}
                rows={1}
            />
            {value !== apiKey.value && (
                <Button
                    variant="contained"
                    onClick={() => {
                        updateApiKey(apiKey.id!, { value })
                            .catch(error => {
                                throw error;
                            })
                            .finally(() => {
                                location.reload();
                            });
                    }}
                >
                    Save
                </Button>
            )}
        </Box>
    );
};

export default ApiKeyEdit;
